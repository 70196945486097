<template>
    <div class="container" :style="{width: screenWidth + 'px', height: screenHeight + 'px'}">
        <div class="box">
            <h2>信息管理系统</h2>
            <el-input style="margin-top: 10px" placeholder="请输入用户名" v-model="userName"/>
            <el-input style="margin-top: 10px" placeholder="请输入账号" v-model="account"/>
            <el-input style="margin-top: 10px" placeholder="请输入密码" v-model="password" show-password/>
            <div class="login_btn" @click="loginAction">注册</div>
            <div class="register_btn" @click="toRegister">已经有账号?去登录</div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Network from "@/util/Network";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Login",
    data() {
        return {
            userName: "",
            account: "",
            password: ""
        }
    },
    computed: {
        ...mapState([
            "screenHeight",
            "screenWidth"
        ])
    },
    methods: {
        toRegister() {
            this.$router.push({
                path: "/"
            })
        },
        loginAction() {
            if (this.userName.length === 0) {
                this.$message.error("用户名不能为空!")
                return;
            }
            if (this.account.length === 0) {
                this.$message.error("账号不能为空!")
                return;
            }
            if (this.password.length === 0) {
                this.$message.error("密码不能为空!")
                return;
            }
            Network.add("user", {
                userName: this.userName,
                account: this.account,
                password: this.password
            }).then(() => {
                this.$message.success("注册成功!请登录")
                this.$router.back()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 200px;
    background-image: url("../assets/bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .box {
        width: 400px;
        padding: 30px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .login_btn {
            width: 100%;
            background-color: #1FA4FF;
            margin-top: 40px;
            height: 40px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            cursor: pointer;
        }

        .register_btn {
            font-size: 15px;
            margin-top: 15px;
            cursor: pointer;
        }
    }
}
</style>