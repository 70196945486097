<template>
    <div class="container" :style="{width: screenWidth + 'px', height: screenHeight + 'px'}">
        <div class="box">
            <h2>信息管理系统</h2>
            <el-input style="margin-top: 10px" placeholder="请输入账号" v-model="account"/>
            <el-input style="margin-top: 10px" placeholder="请输入密码" v-model="password" show-password/>
            <div class="item_imageCode" v-if="imageInfo">
                <el-input clearable placeholder="请输入验证码" v-model="imageCode"></el-input>
                <el-image class="item_imageCode_img" :src="imageInfo.image" @click="getImageCode"></el-image>
            </div>
            <div class="login_btn" @click="loginAction">登录</div>
            <div class="register_btn" @click="toRegister">没有账号?请注册</div>
        </div>
      <div class="footer">
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #dddddd;font-size: 12px">ICP备案号: 粤ICP备2023100411号-1</a>
      </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Network from "@/util/Network";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Login",
    data() {
        return {
            account: "",
            password: "",
            imageCode: "",
            imageInfo: {
                image: "",
                imageId: ""
            }
        }
    },
    computed: {
        ...mapState([
            "screenHeight",
            "screenWidth"
        ])
    },
    mounted() {
        this.getImageCode()
    },
    methods: {
        getImageCode: function () {
            Network.getImageCode().then(res => {
                this.imageInfo = res
            })
        },
        toRegister() {
            this.$router.push({
                path: "/register"
            })
        },
        loginAction() {
            if (this.account.length === 0) {
                this.$message.error("账号不能为空!")
                return;
            }
            if (this.password.length === 0) {
                this.$message.error("密码不能为空!")
                return;
            }
            Network.login("user", this.account, this.password, this.imageInfo.imageId, this.imageCode).then(res => {
                this.$message.success("登录成功!")
                this.$store.dispatch("setUserInfo", res);
                this.$router.push({
                    path: "/index"
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 200px;
    background-image: url("../assets/bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;


    .box {
        width: 400px;
        padding: 30px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .item_imageCode {
            width: 100%;
            margin-top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .item_imageCode_img {
                width: auto;
                height: 100%;
                border-radius: 5px;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .login_btn {
            width: 100%;
            background-color: #1FA4FF;
            margin-top: 40px;
            height: 40px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            cursor: pointer;
        }

        .register_btn {
            font-size: 15px;
            margin-top: 15px;
            cursor: pointer;
        }
    }

  .footer{
    width: 100%;
    position: fixed;
    bottom: 10px;
  }
}
</style>