import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/prizeDraw', name: '抽奖', component: () => import('../views/PrizeDraw/index')
}, {
        path: '/uploadStaff', name: '上传信息', component: () => import('../views/uploadStaff')
}, {
        path: '/skip', name: '跳转', component: () => import('../views/skip/skip')
}, {
        path: '/info', name: '跳转', component: () => import('../views/skip/addCard')
}, {
        path: '/card/active', name: '激活会员卡', component: () => import('../views/active/index')
}, {
        path: '/card/success', name: '激活会员卡', component: () => import('../views/active/success')
}, {
        path: '/card/myInfo', name: '我的信息', component: () => import('../views/cardInfo/index')
}, {
        path: '/card/hxCoupon', name: '餐券信息', component: () => import('../views/cardInfo/HxCoupon')
}, {
        path: '/card/addCard', name: '领取会员卡', component: () => import('../views/addCard/index')
}, {
        path: '/gz/myInfo', name: '我的信息', component: () => import('../views/gzUserInfo/index')
}, {
        path: '/', name: '登录', component: Login
}, {
        path: '/register', name: '注册', component: Register
}, {
        path: '/index', name: 'Index', component: () => import('../views/Index.vue'), redirect: "/admin", children: [{
                path: '/admin', name: '管理员', component: () => import('../views/Admin/AdminList.vue'), meta: {
                        keepAlive: false
                }
        }, {
                path: '/sourceList',
                name: '活动管理',
                component: () => import('../views/Source/SourceList.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/addSource', name: '新增活动', component: () => import('../views/Source/AddSource.vue'), meta: {
                        keepAlive: false
                }
        }, {
                path: '/roomJoinRecord',
                name: '房间入住记录',
                component: () => import('../views/room/RoomJoinRecord.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/addAdmin',
                name: '编辑/新增管理员',
                component: () => import('../views/Admin/AddAdmin.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/staff', name: '工作人员', component: () => import('../views/staff/StaffList.vue'), meta: {
                        keepAlive: false
                }
        }, {
                path: '/addStaff', name: '编辑/新增工作人员', component: () => import('../views/staff/AddStaff.vue')
        }, {
                path: '/addAgency',
                name: '编辑/新增代理',
                component: () => import('../views/agency/AddAgency.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/agencyList',
                name: '代理列表',
                component: () => import('../views/agency/AgencyList.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/addUser', name: '编辑/新增用户', component: () => import('../views/user/AddUser.vue'), meta: {
                        keepAlive: false
                }
        }, {
                path: '/userList', name: '用户列表', component: () => import('../views/user/UserList.vue'), meta: {
                        keepAlive: true
                }
        }, {
                path: '/signList', name: '签到列表', component: () => import('../views/sign/SignList.vue'), meta: {
                        keepAlive: false
                }
        }, {
                path: '/addRoom', name: '编辑/新增房间', component: () => import('../views/room/AddRoom.vue'), meta: {
                        keepAlive: false
                }
        }, {
                path: '/roomList', name: '房间列表', component: () => import('../views/room/RoomList.vue'), meta: {
                        keepAlive: false
                }
        }, {
                path: '/addCoupon',
                name: '编辑/新增卡券',
                component: () => import('../views/coupon/AddCoupon.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/couponList',
                name: '卡券列表',
                component: () => import('../views/coupon/CouponList.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/qrSetting', name: '二维码管理', component: () => import('../views/QrSetting.vue'), meta: {
                        keepAlive: false
                }
        }, {
                path: '/voteThree', name: '投票管理', component: () => import('../views/voteThree/index')
        }, {
                path: '/voteTwo', name: '投票管理2', component: () => import('../views/voteTwo/index')
        }, {
                path: '/rent', name: '租借物品', component: () => import('../views/rent/index')
        }, {
                path: '/voteFolder', name: '投票文件夹', component: () => import('../views/voteThree/voteFolder')
        }, {
                path: '/voteFolderTwo', name: '投票文件夹2', component: () => import('../views/voteTwo/voteFolder')
        }, {
                path: '/uploadIndex',
                name: '抽奖人员',
                component: () => import('../views/PrizeDraw/uploadIndex.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/indexFolder',
                name: '抽奖人员管理',
                component: () => import('../views/PrizeDraw/indexFolder.vue'),
                meta: {
                        keepAlive: false
                }
        }, {
                path: '/staffUploadList', name: '工作人员上传', component: () => import('../views/StaffUploadList')
        }, {
                path: '/addStaffUpload',
                name: '编辑工作人员上传',
                component: () => import('../views/AddStaffUpload.vue'),
                meta: {
                        keepAlive: false
                }
        }]
}]

const router = new VueRouter({
        mode: 'history', base: process.env.BASE_URL, routes
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
        return originalPush.call(this, location).catch(() => {
        })
};
export default router