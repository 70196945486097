import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: null,
        screenWidth: 0,
        screenHeight: 0,
        routeInfo: null,
        rankOne: [

        ],
        rankTwo: [],
        rankThree: [],
        filterNum: 2
    },
    mutations: {
        setFilterNum(state, filterNum) {
            state.filterNum = filterNum
        },
        setRankOne(state, rankOne) {
            state.rankOne = rankOne
        },
        setRankTwo(state, rankTwo) {
            state.rankTwo = rankTwo
        },
        setRankThree(state, rankThree) {
            state.rankThree = rankThree
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setScreenWidth(state, screenWidth) {
            state.screenWidth = screenWidth
        },
        setScreenHeight(state, screenHeight) {
            state.screenHeight = screenHeight
        },
        setRouteInfo(state, routeInfo) {
            state.routeInfo = routeInfo
        },
    },
    actions: {
        setFilterNum(context, filterNum) {
            context.commit('setFilterNum', filterNum)
        },
        setRankOne(context, rankOne) {
            context.commit('setRankOne', rankOne)
        },
        setRankTwo(context, rankTwo) {
            context.commit('setRankTwo', rankTwo)
        },
        setRankThree(context, rankThree) {
            context.commit('setRankThree', rankThree)
        },
        setRouteInfo(context, routeInfo) {
            context.commit('setRouteInfo', routeInfo)
        },
        setUserInfo(context, userInfo) {
            context.commit('setUserInfo', userInfo)
        },
        setScreenWidth(context, screenWidth) {
            context.commit('setScreenWidth', screenWidth)
        },
        setScreenHeight(context, screenHeight) {
            context.commit('setScreenHeight', screenHeight)
        }
    },
    modules: {}
})
