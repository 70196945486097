"use strict";

import axios from "axios";
import {Loading, Message} from 'element-ui';
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    // baseURL: "http://127.0.0.1:8086"
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);
let loadingInstance = null
_axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    loadingInstance = Loading.service({
        background: 'transparent',
        fullscreen: true
    });
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
_axios.interceptors.response.use(function (response) {
    // Do something with response data
    loadingInstance.close()
    if (response.data.code === 101) {
        window.localStorage.removeItem("userInfo")
    }
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});

window.axios = _axios;

window.go = function (data) {
    return new Promise(((resolve) => {
        let query = data.query;
        if (query !== undefined && query.length > 0) {
            let queryStr = "&";
            for (const queryKey in query) {
                queryStr += (queryKey + "=" + query[queryKey]);
            }
            data.url = data.url + queryStr;
        }
        window.axios.post(data.url, data.data).then(res => {
            if (res.data.code === 200) {
                resolve(res.data.result)
            } else {
                Message.error(res.data.result)
            }
        }, err => {
            Message.error(err)
        })
    }));
}
