export default class Network {
    static getImageCode() {
        return window.go({
            url: "/api/hy_server/api/tool/getImageCode",
            data: {}
        })
    }

    static generate_urllink(page) {
        return window.go({
            url: "/api/hy_server/api/tool/generate_urllink",
            data: {
                page
            }
        })
    }

    // card_active_getCode
    static card_active_getCode(encrypt_code) {
        return window.go({
            url: "/api/hy_server/api/tool/card_active_getCode",
            data: {
                encrypt_code: encrypt_code
            }
        })
    }

    // /hy_server/api/tool/generate_urllink
    static sendTemplateSuccess(data) {
        return window.go({
            url: "/api/hy_server/api/tool/sendTemplateSuccess",
            data: data
        })
    }

    static sendTemplateStatus(data) {
        return window.go({
            url: "/api/hy_server/api/tool/sendTemplateStatus",
            data: data
        })
    }

    // /hy_server/api/tool/getUserList
    static getAttentionList() {
        return window.go({
            url: "/api/hy_server/api/tool/getUserList",
            data: {}
        })
    }

    static seletcList(cardNum) {
        return window.go({
            url: "/api/hy_server/api/data/seletcList",
            data: {
                cardNum: cardNum
            }
        })
    }
    static send(phone) {
        return window.go({
            url: "/api/hy_server/api/tool/send",
            data: {
                phone: phone
            }
        })
    }

    // card_active
    static card_active(data) {
        return window.go({
            url: "/api/hy_server/api/tool/card_active",
            data: data
        })
    }

    static verify(data) {
        return window.go({
            url: "/api/hy_server/api/tool/verify",
            data: data
        })
    }


    static getUnionID(openids) {
        return window.go({
            url: "/api/hy_server/api/tool/getUnionID",
            data: {
                openids: openids
            }
        })
    }

    static getQrCode(id) {
        return window.go({
            url: "/api/hy_server/api/tool/getQrCode?id=" + id,
            data: {}
        })
    }

    static login(tableName, account, password, imageId, code) {
        return window.go({
            url: "/api/hy_server/api/data/login",
            data: {
                isBlur: "0",
                tableName,
                account,
                password,
                imageId,
                code,
                selectTable: "no"
            }
        })
    }

    static add(tableName, data) {
        return window.go({
            url: "/api/hy_server/api/data/add",
            data: {
                tableName,
                data,
                selectTable: "no"
            }
        })
    }

    static list(tableName, pageNum, pageSize, where, startTime = '', endTime = '', timeKey = '') {
        return window.go({
            url: "/api/hy_server/api/data/list",
            data: {
                isBlur: "0",
                tableName,
                pageNum,
                pageSize,
                where,
                startTime,
                endTime,
                timeKey,
                sortBy: "cTime",
                sortKey: "DESC",
                selectTable: "no"
            }
        })
    }

    static blurList(tableName, pageNum, pageSize, where, startTime = '', endTime = '', timeKey = '') {
        return window.go({
            url: "/api/hy_server/api/data/blurList",
            data: {
                isBlur: "1",
                tableName,
                pageNum,
                pageSize,
                where,
                startTime,
                endTime,
                timeKey,
                sortBy: "cTime",
                sortKey: "DESC",
                selectTable: "no"
            }
        })
    }
    static blurList_fromUserIds(fromUserIds,tableName, pageNum, pageSize, where, startTime = '', endTime = '', timeKey = '') {
        return window.go({
            url: "/api/hy_server/api/data/blurList_fromUserIds",
            data: {
                fromUserIds,
                isBlur: "1",
                tableName,
                pageNum,
                pageSize,
                where,
                startTime,
                endTime,
                timeKey,
                sortBy: "cTime",
                sortKey: "DESC",
                selectTable: "no"
            }
        })
    }

    static exportFile(ids) {
        window.location = "/api/hy_server/api/data/export/user_kh/" + ids
    }

    static exportAllFile() {
        window.location = "/api/hy_server/api/data/exportALL/user_kh"
    }

    static exportVoteList(folderId, name) {
        window.location = `/api/hy_server/api/data/exportVoteList/${folderId}_folder/${name}`
    }

    static updateDataById(tableName, data, id) {
        return window.go({
            url: "/api/hy_server/api/data/updateDataById",
            data: {
                tableName,
                data,
                id,
                selectTable: "no"
            }
        })
    }

    static getDataByIds(tableName, ids) {
        return window.go({
            url: "/api/hy_server/api/data/getDataByIds",
            data: {
                tableName,
                ids,
                selectTable: "no"
            }
        })
    }

    static getDataById(tableName, id) {
        return window.go({
            url: "/api/hy_server/api/data/getDataById",
            data: {
                tableName,
                id,
                selectTable: "no"
            }
        })
    }

    static deleteById(tableName, id) {
        return window.go({
            url: "/api/hy_server/api/data/deleteById",
            data: {
                tableName,
                id,
                selectTable: "no"
            }
        })
    }

    static deleteByIds(tableName, ids) {
        return window.go({
            url: "/api/hy_server/api/data/deleteByIds",
            data: {
                tableName,
                ids,
                selectTable: "no"
            }
        })
    }
    static createTable(tableName) {
        return window.go({
            url: "/api/hy_server/api/data/createTable",
            data: {
                tableName
            }
        })
    }
}
