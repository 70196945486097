<template>
    <div id="app">
        <router-view :style="{width: screenWidth + 'px', height: screenHeight + 'px'}"/>
    </div>
</template>
<script>

export default {
    name: "APP",
    data() {
        return {
            screenWidth: document.documentElement.clientWidth, // 屏幕宽度
            screenHeight: document.documentElement.clientHeight, // 屏幕高度
        }
    },
    created() {
        this.screenWidth = document.documentElement.clientWidth;
        this.screenHeight = document.documentElement.clientHeight;
        this.$store.dispatch("setScreenWidth", this.screenWidth);
        this.$store.dispatch("setScreenHeight", this.screenHeight);
        this.$store.dispatch("setFilterNum", this.screenWidth / 200 -200/200)
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
            sessionStorage.removeItem('store')
        }
        // 在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })
        window.addEventListener('resize', () => {
            this.screenWidth = document.documentElement.clientWidth;
            this.screenHeight = document.documentElement.clientHeight;
            this.$store.dispatch("setScreenWidth", this.screenWidth);
            this.$store.dispatch("setScreenHeight", this.screenHeight);
            this.$store.dispatch("setFilterNum", this.screenWidth / 200 -200/200)
        })
    },
    async mounted() {
        this.screenWidth = document.documentElement.clientWidth;
        this.screenHeight = document.documentElement.clientHeight;
        this.$store.dispatch("setScreenWidth", this.screenWidth);
        this.$store.dispatch("setScreenHeight", this.screenHeight);
        this.$store.dispatch("setFilterNum", this.screenWidth / 200 -200/200)
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
            sessionStorage.removeItem('store')
        }
        // 在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })
        window.addEventListener('resize', () => {
            this.screenWidth = document.documentElement.clientWidth;
            this.screenHeight = document.documentElement.clientHeight;
            this.$store.dispatch("setScreenWidth", this.screenWidth);
            this.$store.dispatch("setScreenHeight", this.screenHeight);
            this.$store.dispatch("setFilterNum", this.screenWidth / 200 -200/200)
        })
    },
    methods: {}
}
</script>
<style>

::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}

::-webkit-scrollbar-track {
    background-color: #F6F6F6 !important;
}

/* 滚动条的滑轨背景颜色 */

::-webkit-scrollbar-thumb {
    background-color: #CCCCCC !important;
}

/* 滑块颜色 */

::-webkit-scrollbar-button {
    background-color: #CCCCCC !important;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
}

body, html {
    background-color: #F0F2F4;
    width: 100%;
    height: 100%;
    font-size: 15px;
    color: #555555;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
</style>
